export type ProductFilterResetEvent = {
    event: 'gaEvent';
    eventCategory: 'filter';
    eventAction: 'reset';
};

export const trackProductFilterResetEvent = (): ProductFilterResetEvent => ({
    event: 'gaEvent',
    eventCategory: 'filter',
    eventAction: 'reset',
});
