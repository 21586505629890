export type MenuClickEvent = {
    event: 'menu_click';
    firstLevel: string;
    secondLevel?: string;
};

export const trackMenuClickEvent = (firstLevel: string, secondLevel?: string): MenuClickEvent => ({
    event: 'menu_click',
    firstLevel,
    secondLevel,
});
