import { pxToRem } from './pxToRelativeUnit';

const memoizedClamp = () => {
    const cache: Record<string, string> = {};
    return (
        viewportMinInPx: number,
        viewportTargetInPx: number,
        viewportMaxInPx: number,
        minSizeInPx: number,
        targetSizeInPx: number,
        minValueOverride?: number,
        maxValueOverride?: number
    ): string => {
        const key = `${viewportMinInPx}_${viewportTargetInPx}_${viewportMaxInPx}_${minSizeInPx}_${targetSizeInPx}_${
            minValueOverride || 'auto'
        }_${maxValueOverride || 'auto'}`;

        if (!cache[key]) {
            // Calculate a linear equation based on the 2 points,
            // given by [viewportMinInPx, minSizeInPx] and [viewportTargetInPx, targetSizeInPx].
            // y = m * x + b
            // Get the slope of the equation
            const m = (minSizeInPx - targetSizeInPx) / (viewportMinInPx - viewportTargetInPx);
            // Get the y-intercept
            const b = minSizeInPx - m * viewportMinInPx;

            const clampValue = `clamp(
                ${pxToRem(minValueOverride || minSizeInPx)},
                ${m * 100}vw + ${pxToRem(b)},
                ${pxToRem(maxValueOverride || Math.round(m * viewportMaxInPx + b))}
            )`;
            cache[key] = clampValue;
        }

        return cache[key];
    };
};

/**
 * Get the css clamp value usable for e.g. fluid font size, spacings, etc.
 *
 * @param viewportMinInPx – the viewport at which the minimum size will apply. Typically from mobile design
 * @param viewportTargetInPx – the viewport used to calculate equation for clamp values. Typically from desktop design
 * @param viewportMaxInPx – the viewport at which the maximum size will apply.
 * @param minSizeInPx – minimum size - Also used for calculating slope
 * @param targetSizeInPx – target size at the target viewport. Typically desktop
 * @param minValueOverride - use this to override the minimum value. The slope is calculated from minimum value, but this determines the actual minimum value
 * @param maxValueOverride - use this to set a fixed maximum value
 * @returns e.g. 'clamp(2rem, 1.25vw + 1.5rem, 4.5rem)'
 */
export const getClampValue = memoizedClamp();

/**
 * Function to reduce the amount of arguments needed to send, and create consistent clamp values
 *
 * @param minValue – minimum value at screenwidth 640px
 * @param targetValue – target value at screenwidth 1920px
 * @param minValueOverride - use this to override the minimum value. The slope is calculated from minimum value, but this determines the actual minimum value
 * @param maxValueOverride - use this to set a fixed maximum value
 * @returns e.g. 'clamp(2rem, 1.25vw + 1.5rem, 4.5rem)'
 */

export const getClampFullRange = (
    minValue: number,
    targetValue: number,
    minValueOverride?: number,
    maxValueOverride?: number
): string => {
    return getClampValue(
        640,
        1920,
        3840,
        minValue,
        targetValue,
        minValueOverride,
        maxValueOverride
    );
};
