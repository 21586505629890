export type ExternalLinkClickEvent = {
    event: 'gaEvent';
    eventCategory: 'outgoing links';
    eventAction: 'link';
    eventLabel: string;
};

export const trackExternalLinkClickEvent = (externalLink: string): ExternalLinkClickEvent => ({
    event: 'gaEvent',
    eventCategory: 'outgoing links',
    eventAction: 'link',
    eventLabel: externalLink,
});
