import { Error } from '~/lib/data-contract';

/**
 * Format error response to json
 *
 * @param error
 */
export const errorToJson = async (error: Response): Promise<Error> => {
    const isReadableError = error.body && !error.bodyUsed;
    if (!isReadableError) {
        console.error(error);
        return { level: 'error', errorMessages: undefined };
    }

    try {
        const errorResponse = await error.json();
        return errorResponse;
    } catch {
        return { level: 'error' };
    }
};
