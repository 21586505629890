import React, { useEffect, useMemo } from 'react';
import { AlgoliaConnectorContext } from './AlgoliaConnectorContext';
import algoliasearch from 'algoliasearch';
import { useFrame } from '../../frame';
import { runtimeConfig } from '../../public-variables';

const { SEARCH_HOSTNAME } = runtimeConfig();

type Props = {
    /**
     * Provide a refetch interval in milliseconds to clear the cache of the Algolia client.
     */
    refetchInterval?: number;
};

export const AlgoliaConnectorProvider = ({
    children,
    refetchInterval,
}: React.PropsWithChildren<Props>) => {
    const { data: frame } = useFrame();
    const algoliaApp = frame?.search?.algolia?.appKey ?? '';
    const algoliaToken = frame?.search?.algolia?.apiToken ?? '';

    // We send all requests through a custom worker that's tied to the domain to improve caching
    const url = `${SEARCH_HOSTNAME}/algolia`;
    const client = useMemo(() => algoliasearch(algoliaApp, algoliaToken, { hosts: [url] }), [
        algoliaApp,
        algoliaToken,
        url,
    ]);

    useEffect(() => {
        if (refetchInterval) {
            const refetchIntervalTimer = setInterval(function () {
                client.clearCache();
            }, refetchInterval * 1000);
            return () => clearInterval(refetchIntervalTimer);
        }
    }, [client, refetchInterval]);

    return (
        <AlgoliaConnectorContext.Provider value={client}>
            {children}
        </AlgoliaConnectorContext.Provider>
    );
};
