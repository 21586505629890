import { GTMWindow, TrackableEvent, TrackingConfig, LoadStatus, PromiseOfTracking } from '../model';

/**
 * Resets ecommerce object before pushing a new ecommerce event
 *
 * @param windowReference
 * @param event
 */
const prePushAction = (
    windowReference: GTMWindow,
    event: TrackableEvent & { ecommerce?: unknown }
): void => {
    if (event.ecommerce) {
        windowReference.dataLayer.push({ ecommerce: null });
    }
};

export const pushEventFactory = (
    windowReference: GTMWindow,
    config: TrackingConfig,
    trackingObjectPromise: Promise<LoadStatus>
) => (event: TrackableEvent): PromiseOfTracking => {
    return new Promise((resolve) => {
        trackingObjectPromise.then(({ status, rejectionReason }) => {
            if (status === 'ERROR') {
                resolve({
                    success: false,
                    message: `Error: Rejected tracking ${event.event}. ${rejectionReason}`,
                });
            }
            if (status === 'READY') {
                const timeout = setTimeout(() => {
                    resolve({
                        success: false,
                        message: `Error: Event timed out ${event.event}`,
                    });
                }, config.trackingTimeoutMs);

                prePushAction(windowReference, event);

                windowReference.dataLayer.push({
                    ...event,
                    eventCallback: () => {
                        clearTimeout(timeout);
                        resolve({ success: true });
                    },
                });
            }
        });
    });
};
