import { getHref, getRelativePath } from '../utils';
import { GTMWindow } from '../model';
import { FRANCHISE } from '~/constants/franchise';

type BUType = typeof FRANCHISE;

export interface PageViewData {
    countrySite: string;
    bu: keyof BUType;
    environment: string;
    pageType: string;
    originalLocation: string;
    userID?: string;
    anonymousID?: string;
}

export interface PageViewEvent extends PageViewData {
    event: 'generic_datalayer';
    pageLocation: string;
    pagePath: string;
    solution: 'Webshop';
}

export const trackPageViewEvent = (
    pageViewData: PageViewData,
    windowReference: GTMWindow
): PageViewEvent => {
    const {
        pageType,
        bu,
        countrySite,
        environment,
        originalLocation,
        userID,
        anonymousID,
    } = pageViewData;
    return {
        event: 'generic_datalayer',
        pageLocation: getHref(windowReference),
        pagePath: getRelativePath(windowReference),
        pageType,
        bu,
        countrySite,
        environment,
        solution: 'Webshop',
        originalLocation,
        userID,
        anonymousID,
    };
};
