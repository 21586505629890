import { baseColors, Colors } from './baseColors';

export const solidAshColors: Colors = {
    ...baseColors,
    dark: '#1C1A1A',
    dark50: '#4D494B',
    dark70: '#322F31',
    light: '#FFFFFF',
    light30: '#F2F1F0',
    light40: '#E5E4E2',
    light50: '#B2B1AE',
    discount: baseColors.orange50,
};
