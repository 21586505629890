import React from 'react';
import type { AppProps } from 'next/app';
import { ThemeProvider } from '$theme';
import { Franchise } from '~/templates/pages';
import { FranchiseProvider } from '~/shared/utils/franchise';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Hydrate, QueryClientProvider } from 'react-query';
import {
    isDevelopment,
    GtmProvider,
    SettingsProvider,
    AlgoliaConnectorProvider,
    minutes,
} from '~/shared/utils';
import { SessionProvider } from 'next-auth/react';
import { DynamicPageProps } from '~/pages/[[...slug]]';
import { Session } from 'next-auth';
import { useNewQueryClient } from '~/shared/utils/query-client/hooks/useNewQueryClient';

type CustomAppProps<P = unknown> = {
    pageProps: P & { session: Session };
} & Omit<AppProps<P>, 'pageProps'>;

export const App = ({ Component, pageProps }: CustomAppProps<DynamicPageProps>) => {
    const queryClient = useNewQueryClient();
    const { franchise, dehydratedState, settings, session } = pageProps;

    return (
        <QueryClientProvider client={queryClient}>
            <Hydrate state={dehydratedState}>
                <SettingsProvider value={settings}>
                    <FranchiseProvider value={franchise as Franchise}>
                        <AlgoliaConnectorProvider refetchInterval={minutes(30)}>
                            {/* <CacheProvider value={CacheWithConfig}> */}
                            <ThemeProvider>
                                <GtmProvider>
                                    <SessionProvider session={session} refetchInterval={3 * 60}>
                                        <Component {...pageProps} />
                                    </SessionProvider>
                                </GtmProvider>
                            </ThemeProvider>
                            {/* </CacheProvider> */}
                        </AlgoliaConnectorProvider>
                    </FranchiseProvider>
                </SettingsProvider>
                {isDevelopment && <ReactQueryDevtools initialIsOpen={false} />}
            </Hydrate>
        </QueryClientProvider>
    );
};

export default App;
