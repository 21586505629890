import { transparentize } from 'color2k';
import { getBase } from '~/theme/themes/base';
import { solidAshColors as colors } from '~/theme/themes/colors/solidAshColors';
import { solidAshFontSettings } from './fontSettings/solidAshFontSettings';

const themeBase = getBase();

export const getSolidAsh = () => ({
    ...themeBase,
    fontSizes: solidAshFontSettings.sizes,
    fontSizesFluid: solidAshFontSettings.sizesFluid,
    colors,
    fonts: {
        headline: '"Frank Ruhl Libre","Frank Ruhl Libre fallback"',
        body: '"HKGrotesk","HKGrotesk-fallback"',
        mono: 'monospace',
    },
    borders: {
        border: `1px solid ${colors.dark50}`,
        outline: `1px solid ${colors.dark50}`,
        borderTransparent: `1px solid transparent`,
        borderNegative: `1px solid ${colors.red}`,
        radius: 4,
    },
    sizes: {
        ...themeBase.sizes,
        metaHeight: 26,
        logoHeaderWidth: 140,
        TotalHeaderHeight: 50, // Main bar plus search bar
    },
    buttons: {
        fontWeights: {
            sm: 500,
            md: 600,
            lg: 600,
        },
    },
    priceGroup: {
        colors: {
            default: colors.dark,
            original: colors.dark50,
            discount: colors.discount,
        },
        weight: {
            default: 600,
            original: 500,
            discount: 600,
        },
    },
    traits: {
        ...themeBase.traits,
        headerIsSticky: true,
        line: {
            width: 1,
            color: colors.light40,
            colorInverted: colors.dark,
            colorAlternate: colors.dark50,
            asBorderStyle: `1px solid ${colors.light40}`,
            stylisticVisible: false,
        },
        background: {
            default: colors.dark,
            n11meta: colors.dark,
            n21mainMenu: colors.light,
        },
        contentCard: {
            textPosition: 'on',
        },
        image: {
            hover: {
                backgroundColorIdle: transparentize(colors.dark, 1),
                backgroundColorActive: transparentize(colors.dark, 0.8),
                boxShadowIdle: '',
                boxShadowActive: '',
            },
        },
        tooltip: {
            backgroundColor: colors.dark70,
            shade: 'paragraphInverted',
            border: 'none',
        },
        lineSpinner: {
            dark: colors.dark50,
            light: colors.light50,
        },
        sticky: {
            gutterTop: 155,
            gutterBottom: 24,
        },
        navigationIndicator: {
            background: colors.light50,
        },
    },
    text: {
        color: {
            headline: colors.dark,
            paragraph: colors.dark70,
            headlineInverted: colors.light,
            paragraphInverted: colors.light30,
        },
        variants: {
            display1: solidAshFontSettings.sizesFluid['5xl'],
            display2: solidAshFontSettings.sizesFluid['4xl'],
            display3: solidAshFontSettings.sizesFluid['3xl'],
            display4: solidAshFontSettings.sizesFluid['2xl'],
            display5: solidAshFontSettings.sizesFluid['xl'],
            display6: solidAshFontSettings.sizesFluid['lg'],
            body: solidAshFontSettings.sizesFluid['lgMin'],
            bodySm: solidAshFontSettings.sizesFluid['mdMin'],
            bodyLg: solidAshFontSettings.sizesFluid['lgMin'],
            bodyXl: solidAshFontSettings.sizesFluid['2xlMin'],
            caption: solidAshFontSettings.sizes['xs'],
            link: solidAshFontSettings.sizes['md'],
            quote: solidAshFontSettings.sizes['2xl'],
            overline: solidAshFontSettings.sizes['xs'],
        },
        weights: {
            bold: 600,
            strong: 700,
        },
    },
});
