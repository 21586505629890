import { isBrowser } from '../utils';
import { GTMWindow } from '$shared/utils/gtm/lib/model';

const refs: { [key: string]: Promise<void> | undefined } = {};

export const loadScript = (
    windowReference: Window,
    url: string
): { loadPromise: Promise<void>; alreadyExists?: boolean } => {
    if (refs[url]) {
        return { loadPromise: refs[url] as Promise<void>, alreadyExists: true };
    }

    const maxAmountOfTries = 20;
    const sleep = 250;
    let tries = 0;

    refs[url] = new Promise<void>((resolve, reject): void => {
        const testGtmLoaded = (): void => {
            if (
                isBrowser(windowReference) &&
                ((windowReference as unknown) as GTMWindow)?.google_tag_manager
            ) {
                return resolve();
            }
            if (tries === maxAmountOfTries || !isBrowser(windowReference)) {
                return reject('Err, GTM is unavailable.');
            }
            tries++;
            setTimeout((): void => testGtmLoaded(), sleep);
        };
        testGtmLoaded();
    });

    return { loadPromise: refs[url] as Promise<void> };
};
