import { pxToRem, getClampFullRange } from '~/theme/utils';
import { FontSettings } from './baseFontSettings';

export const solidAshFontSettings: FontSettings = {
    /**
     * @description px value will be converted to rem
     * - '2xs' → 10px
     * - xs → 12px
     * - sm → 14px
     * - md → 16px
     * - lg → 18px
     * - xl → 24px
     * - '2xl' → 28px
     * - '3xl' → 32px
     * - '4xl' → 40px
     * - '5xl' → 48px
     */
    sizes: {
        '2xs': pxToRem('10px'),
        xs: pxToRem('12px'),
        sm: pxToRem('14px'),
        md: pxToRem('16px'),
        lg: pxToRem('18px'),
        xl: pxToRem('20px'),
        '2xl': pxToRem('24px'),
        '3xl': pxToRem('28px'),
        '4xl': pxToRem('32px'),
        '5xl': pxToRem('40px'),
        '6xl': pxToRem('48px'),
    },
    /**
     * @description These sizes scale with the view width.
     * (min)  640px is the cutoff for minimum size, further scaling wont occur below this screenwidth
     * (base) 1920px is desktop size (look at this value if design says something should be e.g. 20px on desktop)
     * (max)  3840px is the cutoff for maximum size, further scaling wont occur above this screenwidth
     * Use the 'min' suffix for fonts that only start scaling from 1920 and up, e.g. 'smMin'
     * - ---- min → base → max
     * - xs: 10px → 12px → 15px
     * - sm: 12px → 14px → 17px
     * - md: 14px → 16px → 19px
     * - lg: 16px → 18px → 21px
     * - xl: 18px → 24px → 33px
     * - '2xl': 20px → 28px → 40px
     * - '3xl': 24px → 32px → 44px
     * - '4xl': 28px → 40px → 58px
     * - '5xl': 32px → 48px → 72px
     */
    sizesFluid: {
        xs: getClampFullRange(10, 12),
        sm: getClampFullRange(12, 14),
        smMin: getClampFullRange(12, 14, 14),
        md: getClampFullRange(14, 16),
        mdMin: getClampFullRange(14, 16, 16),
        lg: getClampFullRange(16, 18),
        lgMin: getClampFullRange(16, 18, 18),
        xl: getClampFullRange(16, 20),
        xlMin: getClampFullRange(16, 20, 20),
        '2xl': getClampFullRange(18, 24),
        '2xlMin': getClampFullRange(18, 24, 24),
        '3xl': getClampFullRange(20, 28),
        '4xl': getClampFullRange(24, 32),
        '5xl': getClampFullRange(28, 40),
        '6xl': getClampFullRange(32, 48),
    },
};
