import { ProductList, GTMProduct } from '../model';

export type ProductImpressionEvent = {
    event: 'eec_impressionview';
    ecommerce: {
        currencyCode?: string;
        impressions: ProductList;
    };
};

const listify = (products: GTMProduct[], impressionSource?: string): ProductList =>
    products.map((product, index) => ({
        ...product,
        position: product.position ? product.position : index,
        ...(impressionSource && { list: impressionSource }),
    }));

export const trackProductImpressionEvent = (
    products: GTMProduct[],
    currencyCode?: string,
    impressionSource?: string
): ProductImpressionEvent => ({
    event: 'eec_impressionview',
    ecommerce: {
        currencyCode,
        impressions: listify(products, impressionSource),
    },
});
