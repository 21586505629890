import React from 'react';
import { Settings } from '~/templates/pages';
import { SettingsContext } from './SettingsContext';

interface SettingsProviderProps {
    value?: Settings;
}

export const SettingsProvider = ({
    children,
    value,
}: React.PropsWithChildren<SettingsProviderProps>) => {
    return <SettingsContext.Provider value={value || {}}>{children}</SettingsContext.Provider>;
};
