import isbot from 'isbot';
import { useRouter } from 'next/router';
import { isSSR } from '~/shared/utils';

/**
 * Hook to detect crawler bots, only to be used in browser.
 * Usable for avoiding to render uneeded content when the visit is from a bot.
 *
 * Can be disabled by adding ?botdetection=0 to the url
 * Usable for making correct Lighthouse and Pagespeed audits
 */
export const useIsBrowserBot = () => {
    const { query } = useRouter();
    const disabled = query.botdetection === '0';

    if (disabled) {
        return false;
    }

    // Make sure everything is enabled, when the scanner from CookieInformation visits
    isbot.clear('CookieInformationScanner');
    const isBrowserBot = !isSSR && isbot(navigator.userAgent);

    return isBrowserBot;
};
