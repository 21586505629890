import { Frame, Franchises } from '~/lib/data-contract';
import { runtimeConfig } from '~/shared/utils/public-variables';
import { fetcher } from './fetcher';

const { API_URL } = runtimeConfig();

/**
 * Helper method for requesting a frame from the api. Requires a franchiseId.
 * Returns headers, status and data.
 */
export async function fetchFrame(
    locale: string,
    franchiseId: Franchises,
    params = {},
    headers?: HeadersInit,
    opts?: RequestInit
) {
    const response = await fetcher<Frame>(
        `${API_URL}/api/${franchiseId}/${locale}/content/frame`,
        { ...params },
        headers,
        opts
    );

    return response.json();
}
