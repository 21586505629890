import { getRelativePath } from '../utils';
import { GTMWindow, ContactType } from '../model';

export type ContactCustomerServiceClickEvent = {
    event: 'gaEvent';
    eventCategory: 'contact';
    eventAction: ContactType;
    eventLabel: string;
};

export const trackContactCustomerServiceClickEvent = (
    windowReference: GTMWindow,
    contactType: ContactType
): ContactCustomerServiceClickEvent => ({
    event: 'gaEvent',
    eventCategory: 'contact',
    eventAction: contactType,
    eventLabel: getRelativePath(windowReference),
});
