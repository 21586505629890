import { getRelativePath } from '../utils';
import { GTMWindow } from '../model';

export type Page404Event = {
    event: 'gaEvent';
    eventCategory: '404 page';
    eventAction: string;
    eventLabel: string;
};

export const track404PageEvent = (windowReference: GTMWindow, referer?: string): Page404Event => ({
    event: 'gaEvent',
    eventCategory: '404 page',
    eventAction: getRelativePath(windowReference),
    eventLabel: referer || '',
});
