import { FRANCHISE } from '~/constants/franchise';
import { Franchises } from '~/lib/data-contract';
import {
    getBase,
    getSolidAsh,
    getSolidMarble,
    getFramedChalk,
    getFramedChalkBison,
} from '~/theme/themes';
import { getFramedIvory } from './themes/framedIvory';

// moving this to base.ts theme make storybook error out
export const themeBase = getBase();
export type ThemeType = typeof themeBase;

const solidAsh = getSolidAsh();
const solidMarble = getSolidMarble();
const framedChalk = getFramedChalk();
const framedIvory = getFramedIvory();
const framedChalkBison = getFramedChalkBison();

export const theme = (franchise?: Franchises): ThemeType => {
    switch (franchise) {
        case FRANCHISE.WAGNER:
        case FRANCHISE.WAGNER_SE:
            return solidMarble;
        case FRANCHISE.TOEJEKSPERTEN:
            return solidAsh;
        case FRANCHISE.LINDBERGH:
            return framedChalk;
        case FRANCHISE.JUNK_DE_LUXE:
            return framedIvory;
        case FRANCHISE.BISON:
            return framedChalkBison;
        default:
            return themeBase;
    }
};
