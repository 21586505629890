/**
 * Used for debbugging purposes
 * logs url and relevant headers
 */
export const printQuery = (url: string, headers: HeadersInit = {}) => {
    if (process.env.VERBOSE_LOG !== 'true') {
        return;
    }
    const { Authorization, ...restHeaders } = headers as { [key: string]: string };
    const printHeaders = Authorization ? { Authorization: '***', ...restHeaders } : headers;
    console.info(
        'Querying',
        url,
        `${headers ? 'with headers: ' + JSON.stringify(printHeaders) : 'without headers'}`
    );
};
