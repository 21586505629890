import { getRelativePath } from '../utils';
import { GTMWindow } from '../model';

export type SocialMediaEvent = {
    event: 'gaEvent';
    eventCategory: 'social';
    eventAction: string;
    eventLabel: string;
};

export const trackSocialMediaEvent = (
    windowReference: GTMWindow,
    mediaType: string
): SocialMediaEvent => ({
    event: 'gaEvent',
    eventCategory: 'social',
    eventAction: mediaType,
    eventLabel: getRelativePath(windowReference),
});
