import { useRouter } from 'next/router';
import { useFranchise } from '~/shared/utils/franchise';
import { useQuery } from 'react-query';
import { fetchFrame } from '~/lib/fetchFrame';

/**
 * Frame hook (top menu, footer, settings, and other things that are not page-specific).
 * Will load initial data provided by context on initial load, and fetch new values when culture or market changes
 * Always try to provide a value, even if it's from the previously loaded frame, but the isLoading will indicate
 * if it's serving stale data
 */
export const useFrame = () => {
    const { locale = '' } = useRouter();
    const { key, queryKey } = useFranchise();

    return useQuery(['frame', { queryKey }], () => fetchFrame(locale, key), {
        retry: false,
        staleTime: 10 * 60 * 1000, // 10 minutes
        refetchOnWindowFocus: true,
    });
};
