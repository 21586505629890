import { ProductWithQuantity } from '../model';

export type RemoveFromBasketEvent = {
    event: 'eec_remove';
    ecommerce: {
        currencyCode?: string;
        remove: {
            products: ProductWithQuantity[];
        };
    };
};

export const trackRemoveFromBasketEvent = (
    products: ProductWithQuantity[],
    currencyCode?: string
): RemoveFromBasketEvent => {
    return {
        event: 'eec_remove',
        ecommerce: {
            currencyCode,
            remove: {
                products,
            },
        },
    };
};
