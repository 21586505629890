export type TrackedFilters = {
    price?: string;
    size?: string;
    colour?: string;
    fit?: string;
};

export type FilterSelectedEvent = {
    event: 'product_filtering';
    price?: string;
    size?: string;
    colour?: string;
    fit?: string;
    currency?: string;
};

export const trackFilterSelectedEvent = (
    { price, size, colour, fit }: TrackedFilters,
    currency?: string
): FilterSelectedEvent => ({
    event: 'product_filtering',
    price,
    size,
    colour,
    fit,
    currency,
});
