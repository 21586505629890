export type VideoEvent = {
    event: 'gaEvent';
    eventCategory: 'video';
    eventAction: string;
    eventLabel: string;
};

export const trackVideoEvent = (eventAction: string, eventLabel: string): VideoEvent => {
    return {
        event: 'gaEvent',
        eventCategory: 'video',
        eventAction,
        eventLabel,
    };
};
