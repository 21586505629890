import { ProductWithQuantity } from '../model';

export type AddToBasketEvent = {
    event: 'eec_add';
    ecommerce: {
        currencyCode?: string;
        add: {
            products: ProductWithQuantity[];
        };
    };
};

export const trackAddToBasketEvent = (
    products: ProductWithQuantity[],
    currencyCode?: string
): AddToBasketEvent => ({
    event: 'eec_add',
    ecommerce: {
        currencyCode,
        add: {
            products,
        },
    },
});
