import { transparentize } from 'color2k';
import { solidMarbleColors as colors } from '~/theme/themes/colors/solidMarbleColors';
import { solidMarbleFontSettings } from './fontSettings/solidMarbleFontSettings';
import { getSolidAsh } from './solidAsh';

const themeSolidAsh = getSolidAsh();

// EXTENDED ON SOLIDASH
export const getSolidMarble = () => ({
    ...themeSolidAsh,
    fontSizes: solidMarbleFontSettings.sizes,
    fontSizesFluid: solidMarbleFontSettings.sizesFluid,
    colors,
    fonts: {
        headline: '"paralucent", "paralucent-fallback"',
        body: '"elza", "elza-fallback"',
        mono: 'monospace',
    },
    sizes: {
        ...themeSolidAsh.sizes,
        TotalHeaderHeight: 52, // It's the magic number, I tells ya!
    },
    traits: {
        ...themeSolidAsh.traits,
        headerIsSticky: true,
        line: {
            width: 1,
            color: colors.light40,
            colorInverted: colors.dark,
            colorAlternate: colors.dark50,
            asBorderStyle: `1px solid ${colors.light40}`,
            stylisticVisible: false,
        },
        background: {
            default: colors.dark,
            n11meta: colors.dark,
            n21mainMenu: colors.light,
        },
        contentCard: {
            textPosition: 'on',
        },
        image: {
            hover: {
                backgroundColorIdle: transparentize(colors.dark, 1),
                backgroundColorActive: transparentize(colors.dark, 0.8),
                boxShadowIdle: '',
                boxShadowActive: '',
            },
        },
        tooltip: {
            backgroundColor: colors.dark70,
            shade: 'paragraphInverted',
            border: 'none',
        },
        lineSpinner: {
            dark: colors.dark50,
            light: colors.light50,
        },
        sticky: {
            gutterTop: 155,
            gutterBottom: 24,
        },
        navigationIndicator: {
            background: colors.light50,
        },
    },
});
