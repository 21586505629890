import { GTMProduct } from '../model';

export type ProductClickEvent = {
    event: 'eec_productclick';
    ecommerce: {
        currencyCode?: string;
        click: {
            actionField: {
                list: string;
            };
        };
        products: GTMProduct[];
    };
};

export const trackProductClickEvent = (
    product: GTMProduct[],
    listSource: string,
    currencyCode?: string
): ProductClickEvent => {
    return {
        event: 'eec_productclick',
        ecommerce: {
            currencyCode,
            click: {
                actionField: {
                    list: listSource,
                },
            },
            products: product,
        },
    };
};
