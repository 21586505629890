import { GTMProduct } from '../model';

export type ProductDetailImpressionEvent = {
    event: 'eec_productview';
    ecommerce: {
        currencyCode?: string;
        detail: {
            actionField: {
                list: string;
            };
            products: GTMProduct[];
        };
    };
};

export const trackProductDetailImpressionEvent = (
    product: GTMProduct[],
    list: string,
    currencyCode?: string
): ProductDetailImpressionEvent => ({
    event: 'eec_productview',
    ecommerce: {
        currencyCode,
        detail: {
            actionField: {
                list,
            },
            products: [{ ...product[0] }],
        },
    },
});
