import { useContext } from 'react';
import { Franchise } from '~/templates/pages';

import { FranchiseContext } from '../context/FranchiseContext';

const useFranchiseContext = () => {
    return useContext(FranchiseContext);
};

let franchiseData: Franchise;

export const useFranchise = () => {
    const franchise = useFranchiseContext();

    if (franchise?.key) {
        franchiseData = franchise;
    }

    return franchiseData || {};
};
