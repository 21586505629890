import { ProductWithQuantity } from '../model';

export type CheckoutEvent = {
    event: 'eec_checkout';
    ecommerce: {
        currencyCode?: string;
        checkout: {
            actionField: {
                step: number;
                option?: string;
            };
            products: ProductWithQuantity[];
        };
    };
};

export const trackCheckoutEvent = (
    products: ProductWithQuantity[],
    step: number,
    currencyCode?: string,
    option?: string
): CheckoutEvent => ({
    event: 'eec_checkout',
    ecommerce: {
        currencyCode,
        checkout: {
            actionField: {
                step,
                option,
            },
            products,
        },
    },
});
