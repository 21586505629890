import React, { useMemo } from 'react';
import { ThemeProvider as EmotionThemeProvider, ThemeProviderProps } from '@emotion/react';
import { theme as themes } from '../theme';
import { useFranchise } from '~/shared/utils';

export const ThemeProvider = (props: Omit<ThemeProviderProps, 'theme'>) => {
    const franchise = useFranchise();
    const key = franchise?.key;

    const theme = useMemo(() => themes(key), [key]);

    return <EmotionThemeProvider theme={theme} {...props} />;
};
