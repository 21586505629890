import { useState } from 'react';
import { QueryClient } from 'react-query';
import { useNotificationState } from '~/shared/hooks/useNotification';
import { errorToJson } from '~/shared/utils';
import { weakKey } from '~/shared/utils/jsx';

export const useNewQueryClient = () => {
    const { add } = useNotificationState();

    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false,
                        staleTime: 5 * 60 * 1000, // 5 minutes
                    },
                    mutations: {
                        // Default mutation error handling
                        onError: async (error) => {
                            const { level = 'error', errorMessages = '' } = await errorToJson(
                                error as Response
                            );

                            const notification = {
                                headline: '',
                                text: errorMessages,
                                severity: level,
                            };
                            add({ ...notification, id: weakKey(notification) });
                        },
                    },
                },
            })
    );

    return queryClient;
};
