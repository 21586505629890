export const baseFontSize = 16; // By default the html base size

const memoizedPxToRelativeUnit = (unit: 'rem' | 'em') => {
    const cache: Record<string, string> = {};
    return (pxValue: number | string): string => {
        const value = parseFloat(pxValue.toString());
        const key = value + unit;
        if (!cache[key]) {
            const calculatedRelativeUnit = `${value / baseFontSize}${unit}`;
            cache[key] = calculatedRelativeUnit;
        }
        return cache[key];
    };
};

export const pxToRem = memoizedPxToRelativeUnit('rem');
export const pxToEm = memoizedPxToRelativeUnit('em');
