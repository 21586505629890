export type FormSubmissionEvent = {
    event: 'gaEvent';
    eventCategory: 'form';
    eventAction: string;
    eventLabel: string;
};

export const trackFormSubmissionEvent = (
    formName: string,
    formType: string
): FormSubmissionEvent => ({
    event: 'gaEvent',
    eventCategory: 'form',
    eventAction: formName,
    eventLabel: formType,
});
