import React, { ReactNode, useState, useMemo } from 'react';
import { isSSR, useFranchise } from '~/shared/utils';
import { useFrame } from '~/shared/utils/frame';
import { configureTracking } from '~/shared/utils/gtm/lib';
import { TrackingObject } from '../lib/model/TrackingObject';
import { GtmContext } from './GtmContext';
import { useIsBrowserBot } from '~/shared/hooks';
import { isDevelopment } from '~/shared/utils/platform';
import { useSettings } from '~/shared/utils/settings';

type Props = { children: ReactNode };

export const GtmProvider = ({ children }: Props) => {
    const { data: frame } = useFrame();
    const { activeMarket } = useFranchise();
    const { referer } = useSettings();
    const [trackingObject, setTrackingObject] = useState<TrackingObject>({} as TrackingObject);
    const isBrowserBot = useIsBrowserBot();
    const trackingSetup = frame?.settings?.trackingSetup;

    const gtmTrackingObject = useMemo(() => ({ ...trackingObject, referer }), [
        referer,
        trackingObject,
    ]);

    if (Object.keys(trackingObject).length === 0) {
        const gtmId = trackingSetup?.gtmId || '';
        const customDomain = trackingSetup?.customDomain || 'https://www.googletagmanager.com';
        const trackingConfig = configureTracking({
            gtmId,
            window: !isSSR ? window : undefined,
            trackingEnabled: Boolean(trackingSetup?.gtmEnabled),
            currency: activeMarket?.currency,
            customDomain,
        });
        trackingConfig.loadComplete.then(({ status, rejectionReason }) => {
            if (
                typeof window !== 'undefined' &&
                status === 'ERROR' &&
                !isBrowserBot &&
                !isDevelopment
            ) {
                console.warn(rejectionReason);
            }
        });
        setTrackingObject(trackingConfig);
    }

    if (isSSR) {
        return <>{children}</>;
    }

    return <GtmContext.Provider value={gtmTrackingObject}>{children}</GtmContext.Provider>;
};
