import React from 'react';
import { Franchise } from '~/templates/pages';
import { FranchiseContext } from './FranchiseContext';

interface FranchiseProviderProps {
    value: Franchise;
}

export const FranchiseProvider = ({
    children,
    value,
}: React.PropsWithChildren<FranchiseProviderProps>) => {
    return <FranchiseContext.Provider value={value}>{children}</FranchiseContext.Provider>;
};
