import { Franchises } from '~/lib/data-contract';

export const FRANCHISE: Record<string, Franchises> = Object.freeze({
    TOEJEKSPERTEN: 10,
    WAGNER: 8,
    WAGNER_SE: 100,
    LINDBERGH: 1337,
    JUNK_DE_LUXE: 1340,
    BISON: 1342,
});
