import { ProductWithQuantity, GTMProduct } from '../model';

export type PurchaseEvent = {
    event: 'eec_purchase';
    ecommerce: {
        currencyCode?: string;
        purchase: {
            actionField: {
                id: string;
                revenue?: string;
                tax?: string;
                shipping?: string;
                coupon?: string[];
                affiliation?: string;
            };
            products: GTMProduct[];
        };
    };
};

export const trackPurchaseEvent = (
    orderId?: string,
    products?: ProductWithQuantity[],
    totalPrice?: string,
    currencyCode?: string,
    shippingPrice?: string,
    vatPrice?: string,
    coupon?: string[]
): PurchaseEvent => ({
    event: 'eec_purchase',
    ecommerce: {
        currencyCode,
        purchase: {
            actionField: {
                id: orderId || '',
                revenue: totalPrice,
                tax: vatPrice,
                shipping: shippingPrice,
                coupon,
                affiliation: 'Webshop',
            },
            products: products || [],
        },
    },
});
