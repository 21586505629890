export const isSSR = typeof window === 'undefined';
export const isBrowser = !isSSR;
export const isDevelopment = process.env.NODE_ENV === 'development';

// Useful for checking if your url is a "real" url. Needed for e.g. CookieInformation
export let isLocalHostname = false;
if (!isSSR) {
    isLocalHostname =
        window?.location.hostname === 'localhost' || window?.location.hostname === 'local';
}
