import { getBase } from '~/theme/themes/base';
import { framedChalkColors } from '~/theme/themes/colors/framedChalkColors';

const themeBase = getBase();

export const getFramedChalkBison = () => ({
    ...themeBase,
    fonts: {
        headline: '"EB Garamond", "EB Garamond fallback"',
        body: '"Avenir Roman", "Avenir Roman fallback"',
        mono: 'monospace',
    },
    colors: framedChalkColors,
    traits: {
        ...themeBase.traits,
        headerIsSticky: true,
    },
    sizes: {
        ...themeBase.sizes,
        TotalHeaderHeight: 44,
    },
});
