import { getFramedChalk } from '.';

const themeFramedChalk = getFramedChalk();

// EXTENDED ON framedChalk
export const getFramedIvory = () => ({
    ...themeFramedChalk,
    fonts: {
        headline: '"futura-pt", "futura-pt-fallback"',
        body: '"futura-pt", "futura-pt-fallback"',
        mono: 'monospace',
    },
});
