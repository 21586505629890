import { ProductWithQuantity } from '../model';

export type MiniBasketAddToBasketEvent = {
    event: 'eec_add';
    ecommerce: {
        currencyCode?: string;
        detail: {
            actionField: { list: 'Minibasket' };
            products: ProductWithQuantity[];
        };
    };
};

export const trackMiniBasketAddToBasketEvent = (
    products: ProductWithQuantity[],
    currencyCode?: string
): MiniBasketAddToBasketEvent => ({
    event: 'eec_add',
    ecommerce: {
        currencyCode,
        detail: {
            actionField: { list: 'Minibasket' },
            products,
        },
    },
});
