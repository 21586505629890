import { getHref } from '../utils';
import { GTMWindow } from '../model';

export type DownloadFileEvent = {
    event: 'gaEvent';
    eventCategory: 'download';
    eventAction: string;
    eventLabel: string;
};

export const trackDownloadEvent = (
    windowReference: GTMWindow,
    fileName: string
): DownloadFileEvent => ({
    event: 'gaEvent',
    eventCategory: 'download',
    eventAction: fileName,
    eventLabel: getHref(windowReference),
});
