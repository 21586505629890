// Theme colors
const dark = '#0D0D0D';
const dark50 = '#717276';
const dark70 = '#3C3D42';

const light = '#FFFFFF';
const light30 = '#F0F0F0';
const light40 = '#E5E4E2';
const light50 = '#A5A5A6';

const orange50 = '#FA532C';

// Semantic colors

// Success
const green10 = '#97E5BF';
const green50 = '#2FCB80';
const green90 = '#299762';
const green = green50;

// Warning
const yellowXLight = '#FFF4CD';
const yellowLight = '#FFE791';
const yellow10 = '#FFE791';
const yellow50 = '#FFCF23';
const yellow90 = '#DEA508';
const yellow = yellow50;

// Error
const red10 = '#FF7171';
const red50 = '#D81212';
const red90 = '#AF0000';
const red = red50;

const success = green;
const information = dark;
const warning = yellow;
const error = red;

// Accent
const blue10 = '#328CFC';
const blue20 = '#236DF9';
const blue50 = '#123ED8';
const blue90 = '#0005C7';
const blue = blue50;
const accent = blue;

// Discount
const discount = red50;
const campaign = orange50;

// Colors that are slighty transparent
const transparentLight = 'rgba(255, 255, 255, 0.75)';
const transparentDark = 'rgba(165, 165, 166, 0.20)';

// Form colors
const formFieldPlaceholder = dark50;
const formFieldText = dark;
const formFieldActiveText = dark70;
const formFieldFocusText = dark70;
const formFieldBackground = light;
const formFieldActiveBoxshadow = dark;
const formFieldActiveBackground = light30;

export const baseColors = {
    dark,
    dark50,
    dark70,
    light,
    light30,
    light40,
    light50,
    green,
    green10,
    green50,
    green90,
    yellow,
    yellowXLight,
    yellowLight,
    yellow10,
    yellow50,
    yellow90,
    red,
    red10,
    red50,
    red90,
    orange50,
    blue,
    blue10,
    blue20,
    blue50,
    blue90,
    success,
    information,
    warning,
    error,
    transparentLight,
    transparentDark,
    accent,
    discount,
    campaign,
    formFieldPlaceholder,
    formFieldText,
    formFieldActiveText,
    formFieldFocusText,
    formFieldBackground,
    formFieldActiveBoxshadow,
    formFieldActiveBackground,
};

export type Colors = { [key in keyof typeof baseColors]: string };
