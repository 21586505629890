import { getHref } from '../utils';
import { GTMWindow } from '../model';

export type SearchQueryEvent = {
    event: 'gaEvent';
    eventCategory: 'search';
    eventAction: string;
    eventLabel: string;
};

export const trackSearchQueryEvent = (
    windowRefernece: GTMWindow,
    query: string
): SearchQueryEvent => ({
    event: 'gaEvent',
    eventCategory: 'search',
    eventAction: query,
    eventLabel: getHref(windowRefernece),
});
