import { getBase } from '~/theme/themes/base';
import { framedChalkColors } from '~/theme/themes/colors/framedChalkColors';

const themeBase = getBase();
export type ThemeType = typeof themeBase;

export const getFramedChalk = (): ThemeType => ({
    ...themeBase,
    colors: framedChalkColors,
    traits: {
        ...themeBase.traits,
        headerIsSticky: true,
    },
    sizes: {
        ...themeBase.sizes,
        N20headerHeight: 45,
        TotalHeaderHeight: 45,
    },
});
